import { SenecaResponse, StompMessageContext, Note, TopicMessage, TopicMessageContainer } from "atfcore-commonclasses";
class ItemChat {
    user: any;
    timestamp: any;
    notes: Array<Note>;
    newMessages: number;
}

var Chat = (function () {
    "use strict";
    var self = this;
    var $chatSection: JQuery = null;
    var $chatSection_history: JQuery = null;
    var $chatUsersSection: JQuery = null;
    var $chatUsersSection_List: JQuery = null;
    var $webinarUsersSection: JQuery = null;
    var $webinarUsersSection_list: JQuery = null;
    var $btnCloseUsers: JQuery = null;
    var $btnCloseWebinarUsers: JQuery = null;
    var $btnCloseChat: JQuery = null;
    var $btnChat: JQuery = null;
    var $btnUsers: JQuery = null;
    var $inputMessage: JQuery = null;
    var $btnSendMessage: JQuery = null;
    var $newMessages: JQuery = null;
    let userMsgNotifyPrefix = "user-msg-notify-";
    let deviceId: string = null;
    let baseUrl: string = null;
    let itemId: string = null;
    let broadcastingQueueChatName: string;
    let chatUsers: Array<ItemChat> = [];
    let loggedUser: any;
    let busService: any
    let globalApplicationData: any;
    let teacherMode: boolean;
    let teacher: ItemChat;
    let personToContact: ItemChat;
    let newMessages: number;
    let intervals: any;
    var moment: any;

    //Bus
    let stompClient: any;

    return {

        // public functions
        init: function (globalApplicationData: any, itemId: string, busService: any, $window: ng.IWindowService) {
            console.log("Chat initialization");
            self = this;
            self.globalApplicationData = globalApplicationData;
            self.baseUrl = self.globalApplicationData.applicationContext;
            self.loggedUser = self.globalApplicationData.jwtPayload.user;
            self.itemId = itemId;
            self.newMessages = 0;
            self.teacherMode = true;
            self.deviceId = self.globalApplicationData.deviceId == null ? "1" : self.globalApplicationData.deviceId;
            self.busService = busService;
            self.intervals = [];

            $chatSection = $("#chat-section");
            $chatSection_history = $chatSection.find('.chatDetails').find('.sectionDetails');
            $chatUsersSection = $("#chat-users-section");
            $chatUsersSection_List = $chatUsersSection.find('.userListDetails').find('.sectionDetails');
            $webinarUsersSection = $("#webinar-users-section");
            $webinarUsersSection_list = $webinarUsersSection.find('.userListDetails').find('.sectionDetails');
            $btnCloseWebinarUsers = $("#btn-close-webinar-users");
            $btnCloseUsers = $("#btn-close-users");
            $btnCloseChat = $("#btn-close-chat");
            $btnChat = $("#btn-chat");
            $btnUsers = $("#btn-users");
            $inputMessage = $("#input-message");
            $btnSendMessage = $("#chat-send-message-btn");

            // eventHandler
            $btnCloseWebinarUsers.on("click touch", onBtnCloseWebinarUsers);
            $btnCloseChat.on("click touch", onBtnCloseChat);
            $btnCloseUsers.on("click touch", onBtnCloseUsers);
            $btnSendMessage.on("click touch", onSendMessage);
            $btnChat.on("click touch", onBtnMenuChat);

            //init state
            $chatSection.hide();
            $chatUsersSection.hide();
            refreshChatUsers();

        },

        setUser: function (id: string, name: string, surname: string) {
            if (!self.chatUsers) self.chatUsers = [];
            let foundIt = false;
            if (id) {

                for (let i = 0; i < self.chatUsers.length; i++) {
                    let chatUser = self.chatUsers[i];
                    if (chatUser && chatUser.user.id == id) {
                        //esiste già devo aggiornare il timestamp
                        let timestamp = new Date();
                        timestamp.setTime(new Date().getTime() + (2 * 60 * 1000));
                        chatUser.timestamp = timestamp;
                        foundIt = true;
                        break;
                    }
                }

                if (foundIt == false) {
                    //non esiste nella lista devo aggiungerlo
                    var chatUser = new ItemChat();
                    let userItem = {
                        id: id,
                        forename: name ? name : "undefined",
                        surname: surname ? surname : "undefined"
                    };
                    let timestamp = new Date();
                    timestamp.setTime(new Date().getTime() + (2 * 60 * 1000));
                    chatUser.timestamp = timestamp;
                    chatUser.user = userItem;
                    chatUser.newMessages = 0;
                    chatUser.notes = [];
                    self.chatUsers.push(chatUser);
                    loadChatHistory();

                }
                updateUsersChat();
                updateUsersWebinar();
            }

        },

        //mi serve il teacher e sapere se io sono un teacher 
        setTeacher: function (id: string, name: string, surname: string) {

            if (id) {
                var chatUser = new ItemChat();
                let userItem = {
                    id: id,
                    forename: name ? name : "undefined",
                    surname: surname ? surname : "undefined"
                };

                chatUser.user = userItem;
                chatUser.newMessages = 0;
                chatUser.notes = [];

                self.teacher = chatUser;
                self.teacherMode = false;
                sendImLive();
                if ($chatSection.hasClass("teachermode")) {
                    $chatSection.removeClass("teachermode");
                }

                //abilito il chiudi sezione per il div della chat history
                if ($chatSection.find('.closeSide').hasClass('display-none')) {
                    $chatSection.find('.closeSide').removeClass('display-none')
                }

                self.chatUsers = []; //se non sono un teacher posso chattare solo con il teacher 
                self.chatUsers.push(chatUser);
                loadChatHistory();
            }
            else {
                self.teacherMode = true;
                if (!$chatSection.hasClass("teachermode")) {
                    $chatSection.addClass("teachermode");
                }
            }
        },

        bindBtnMenu: function () {
            $btnChat = $("#btn-chat");
            $btnChat.unbind("click touch");
            $btnChat.on("click touch", onBtnMenuChat);

            $btnUsers = $("#btn-users");
            $btnUsers.unbind("click touch");
            $btnUsers.on("click touch", onBtnMenuUsers);

            $btnCloseWebinarUsers = $("#btn-close-webinar-users");
            $btnCloseWebinarUsers.unbind("click touch");
            $btnCloseWebinarUsers.on("click touch", onBtnCloseWebinarUsers);
        },

        closeSections: function () {
            if ($chatUsersSection) $chatUsersSection.hide();
            if ($chatSection) $chatSection.hide();
        },

        //chi cè connesso al webinar?
        whoIsLive: function () {
            let userToken = self.globalApplicationData.identityToken
            //chiedo al mediator chi è connesso al mio webinar
            //solo se sono teacher mi serve sapere chi cè connesso 
            //if (self.teacherMode) { 
            // preparo il messaggio stomp
            let stompContext: StompMessageContext = {
                userId: self.loggedUser.userId,
                referenceType: "ITEM", // todo: ReferenceTypes.ITEM
                referenceId: self.itemId,
                token: userToken,
                uniqueDevice: self.deviceId,
                action: "who-is-live" // todo: WebsocketEvents.SIGNALING_START_PRESENTER
            };

            let topicMessage: TopicMessage = {
                referenceType: "WEBINAR_SIGNALING", // todo: ReferenceTypes.WEBINAR_SIGNALING
                data: {},
                additionalData: null
            };

            let messageContainer: TopicMessageContainer = {
                body: topicMessage,
                customProperties: stompContext
            };

            self.busService.sendSignalingMessage(messageContainer);
            //}
            //chiedo al mediator chi è connesso al mio webinar
        },

        //chi cè connesso al webinar?
        imLive: function () {
            let userToken = self.globalApplicationData.identityToken
            // preparo il messaggio stomp
            let stompContext: StompMessageContext = {
                userId: self.loggedUser.userId,
                referenceType: "ITEM", // todo: ReferenceTypes.ITEM
                referenceId: self.itemId,
                token: userToken,
                uniqueDevice: self.deviceId,
                action: "im-live" // todo: WebsocketEvents.SIGNALING_START_PRESENTER
            };

            let topicMessage: TopicMessage = {
                referenceType: "WEBINAR_SIGNALING", // todo: ReferenceTypes.WEBINAR_SIGNALING
                data: { userId: self.loggedUser.userId, forename: self.loggedUser.forename, surname: self.loggedUser.surname },
                additionalData: null
            };

            let messageContainer: TopicMessageContainer = {
                body: topicMessage,
                customProperties: stompContext
            };

            self.busService.sendSignalingMessage(messageContainer);
        },

        messageBroadcastingWebinarQueue: function (message: any) {
            try {
                console.log('incoming:', message);
                if (message) {
                    //message.ack();
                    let topicMessageContainer = JSON.parse(message.body);
                    if (topicMessageContainer && topicMessageContainer.body && topicMessageContainer.customProperties) {
                        if (topicMessageContainer.body.referenceType == "WEBINAR_SIGNALING" && topicMessageContainer.customProperties.referenceType == "ITEM") {
                            switch (topicMessageContainer.customProperties.action) {
                                case ("who-is-live"):
                                    if (!self.teacherMode) {
                                        self.imLive();
                                    }
                                    break;
                                case ("im-live"):
                                    if (self.teacherMode) {
                                        self.setUser(topicMessageContainer.body.data.userId, topicMessageContainer.body.data.forename, topicMessageContainer.body.data.surname);
                                    }
                                    break;
                            }

                        }
                    }
                }
            }
            catch (err) {
                //in caso di errore chiedo di riscaricare la chat
                loadChatHistory();
            }
        },

        messageUserChatQueue: function (message: any) {
            try {
                console.log('incoming:', message);
                if (message.body) {
                    let topicMessageContainer = JSON.parse(message.body);
                    if (topicMessageContainer && topicMessageContainer.body && topicMessageContainer.body.data) {

                        switch (topicMessageContainer.body.referenceType) {
                            case ("CHAT_MESSAGE"):
                                {
                                    let note = JSON.parse(topicMessageContainer.body.data);
                                    if (note) {
                                        let chatUser = self.chatUsers.filter((webinarUser: any) => webinarUser.user.id === note.creationUserId)[0];
                                        if (chatUser) {
                                            chatUser.notes.push(note);

                                            if (self.personToContact && self.personToContact.id == chatUser.user.id) {
                                                onMessage(note, chatUser);
                                                scrollChatDiv();
                                            }
                                            else {
                                                chatUser.newMessages += 1;
                                                self.newMessages += 1;
                                                if (!$newMessages || !$newMessages.length) $newMessages = $("#new-messages");
                                                $newMessages.html(self.newMessages)
                                                let msgNotifyId = "#" + userMsgNotifyPrefix + chatUser.user.id;
                                                var notifyMessage = chatUser.newMessages == 1 ? "1 messaggio non letto" : chatUser.newMessages + " messaggi non letti";
                                                $(msgNotifyId).html(notifyMessage);
                                                if (!$(msgNotifyId).hasClass("newMessage")) $(msgNotifyId).addClass("newMessage")
                                            }

                                        }
                                        else {
                                            loadChatHistory();
                                        }
                                    }
                                }
                                break;
                        }
                    }
                }
                //message.ack();
            }
            catch (err) {
                //in caso di errore chiedo di riscaricare la chat
                loadChatHistory();
            }
        },

        destroyInterval: function () {
            if (self) {
                if (!self.intervals) self.intervals = [];
                for (let i = 0; i < self.intervals.length; i++) {
                    clearInterval(self.intervals[i]);
                }
            }
        }
    };

    // private functions
    //user want to send a new message
    function onSendMessage(event: Event) {
        console.log("event:", event);
        if (self.teacherMode) {
            sendMessageFromTeacherToUser();
        }
        else {
            sendMessageFromUsertoTeacher();
        }
        scrollChatDiv();
    }

    //user open chat history whit another user
    function onBtnChat(event: Event, userId: string) {

        //se non sono un teacher posso parlare solo con un teacher
        if (!self.teacherMode) {
            userId = self.teacher.user.id;
        }

        let chatUser = self.chatUsers.filter((chatUser: any) => chatUser.user.id === userId)[0];
        if (chatUser) {
            $chatSection_history.html("");
            self.personToContact = chatUser.user;
            for (let i = 0; i < chatUser.notes.length; i++) {
                let note = chatUser.notes[i];
                onMessage(note, chatUser);

            }

            //aggiorno il numero di messaggi nella spalla destra item CHAT
            if (!$newMessages || !$newMessages.length) $newMessages = $("#new-messages");
            self.newMessages = self.newMessages - chatUser.newMessages
            if (self.newMessages < 1) {
                self.newMessages = 0;
                $newMessages.html("");
            }
            else {
                $newMessages.html(self.newMessages);
            }
            chatUser.newMessages = 0;
            let msgNotifyId = "#" + userMsgNotifyPrefix + chatUser.user.id;
            $(msgNotifyId).html("Nessun nuovo messagio")
            if ($(msgNotifyId).hasClass("newMessage")) $(msgNotifyId).removeClass("newMessage")
        }
        scrollChatDiv();
        $chatSection.show();
    }


    //append messge to div
    function onMessage(note: any, chatUser: any) {
        let divToAppend = "";
        var creationDate = moment(note.creationDate).format(" - DD/MM/YYYY - HH:mm ");
        if (note.creationUserId == self.loggedUser.userId) {
            divToAppend = '<div id="myMessage"><p><span>' + "Tu </span>" + creationDate + '</p><div class="myMessage">' + note.description + '</div></div>'
        }
        else {
            divToAppend = '<div id="otherMessage"><p><span>' + chatUser.user.forename + " " + chatUser.user.surname + ' </span>' + creationDate + '</p><div class="otherMessage">' + note.description + '</div></div>'
        }
        $chatSection_history.append(divToAppend);
    }


    function onBtnMenuUsers(event: Event) {
        if ($webinarUsersSection == null) {
            $webinarUsersSection = $("#webinar-users-section");
            $webinarUsersSection_list = $webinarUsersSection.find('.userListDetails').find('.sectionDetails');
        }

        if ($webinarUsersSection) {
            if ($webinarUsersSection.hasClass("display-none")) {

                $webinarUsersSection.removeClass("display-none");
            }
            else {
                $webinarUsersSection.addClass("display-none");
            }
        }

        if ($chatUsersSection) {
            $chatUsersSection.hide();
            self.personToContact = null;
        }

        if ($chatSection) {
            $chatSection.hide();
            self.personToContact = null;
        }
    }



    //user want to see  chat users
    function onBtnMenuChat(event: Event) {
        //chiudiamo un eventuale apertura della sezione utenti
        if (self.teacherMode) {
            if (!$webinarUsersSection.hasClass("display-none")) {
                $webinarUsersSection.addClass("display-none");
            }
        }

        if ($chatUsersSection == null) {
            $chatUsersSection = $("#chat-users-section");
            $chatUsersSection_List = $chatUsersSection.find('.chatDetails').find('.sectionDetails');
        }

        if ($chatSection == null) {
            $chatSection = $("#chat-section");
            $chatSection_history = $chatSection.find('.chatDetails').find('.sectionDetails');
        }

        if ($chatUsersSection.is(':visible') || $chatSection.is(':visible')) {
            $chatSection.hide();
            $chatUsersSection.hide();
            self.personToContact = null;
        }
        else {

            if (self.teacherMode) {
                $chatUsersSection.show();
            }
            else {
                onBtnChat(null, null);
            }
        }

    }

    //user want close chat whit another user
    function onBtnCloseChat(event: Event) {
        $chatSection.hide();
        self.personToContact = null;
    }

    //user want close chat user section
    function onBtnCloseUsers(event: Event) {
        if ($chatUsersSection) $chatUsersSection.hide();
        if ($chatSection) $chatSection.hide();
        self.personToContact = null;
    }

    function onBtnCloseWebinarUsers(event: Event) {
        if ($webinarUsersSection) {
            if (!$webinarUsersSection.hasClass("display-none")) {
                $webinarUsersSection.addClass("display-none");
            }
        }
    }

    // get history chat for logged user and item id
    function loadChatHistory() {
        var token = self.globalApplicationData.identityToken;
        if (!self.chatUsers && self.chatUsers.length < 1) {
            return;
        }
        else {
            $.ajax({
                url: self.baseUrl + 'rest-api/bgacademy-mediator/get-full-webinar-chat/' + self.itemId,
                type: 'GET',
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                },
                data: {},
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (senecaResponse) {
                    // Salvo l'oggetto intero
                    if (senecaResponse.response) {
                        //cerco le note per gli utenti
                        self.chatUsers.forEach((webinarUser: any) => {
                            let userNotes = senecaResponse.response.filter((note: any) => note.targetUserId === webinarUser.user.id || note.creationUserId === webinarUser.user.id);
                            //this.$scope.mapChatHistory[webinarUser.user.id] =  [];
                            webinarUser.notes = [];
                            webinarUser.newMessages = 0;
                            if (userNotes && userNotes.length > 0) webinarUser.notes = userNotes;  /*this.$scope.mapChatHistory[user.id] = userNotes; */
                        });
                    }
                    else {
                        //creo cmq la mappa delle chat vuote
                        self.chatUsers.forEach((webinarUser: any) => {
                            webinarUser.notes = [];
                            webinarUser.newMessages = 0;
                        });
                    }
                },
                error: function (data) {
                    //TODO: cosa fare?
                }
            });
        }
    }


    // send message from teacher to user 
    function sendMessageFromTeacherToUser() {
        var token = self.globalApplicationData.identityToken;
        var messageText = $inputMessage.val();
        if (messageText) {
            $.ajax({
                url: self.baseUrl + 'rest-api/bgacademy-mediator/post-webinar-message-from-teacher-to-user/' + self.itemId,
                type: 'POST',
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                },
                data: JSON.stringify({ messageText: messageText, itemId: self.itemId, targetUserId: self.personToContact.id }),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (senecaResponse) {
                    if (senecaResponse.error) {
                        //TODO: CHIAMARE TOASTER
                        // Dati non validi, quindi alzo l'errore
                        //this.toaster.pop("error", this.$translate.instant('error.generic.TITLE'), this.$translate.instant('error.generic.MESSAGE'));
                    } else {
                        //TODO: CHIAMARE TOASTER
                        // Avverto l'utente che l'operazione è andata a buon fine
                        //this.toaster.pop("success", this.$translate.instant('generic.OPERATION_PERFORMED'), this.$translate.instant('courseDetail.QUESTION_SENT'));
                        // e l'aggiungo nella lista delle Comunicazioni
                        let chatUser = self.chatUsers.filter((webinarUser: any) => webinarUser.user.id === self.personToContact.id)[0];
                        if (chatUser) {
                            chatUser.notes.push(senecaResponse.response);
                            onMessage(senecaResponse.response, chatUser);
                            $inputMessage.val('');
                            scrollChatDiv();
                        }

                    }

                },
                error: function (data) {

                }
            });
        }
    }


    // send message from user to teacher 
    function sendMessageFromUsertoTeacher() {
        var token = self.globalApplicationData.identityToken;
        var messageText = $inputMessage.val();
        if (messageText) {
            $.ajax({
                url: self.baseUrl + 'rest-api/bgacademy-mediator/post-webinar-message-to-teacher/' + self.itemId,
                type: 'POST',
                beforeSend: function (xhr) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                },
                data: JSON.stringify({ messageText: messageText, itemId: self.itemId }),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: function (senecaResponse) {
                    if (senecaResponse.error) {
                        //TODO: CHIAMARE TOASTER
                        // Dati non validi, quindi alzo l'errore
                        //this.toaster.pop("error", this.$translate.instant('error.generic.TITLE'), this.$translate.instant('error.generic.MESSAGE'));
                    } else {
                        //TODO: CHIAMARE TOASTER
                        // Avverto l'utente che l'operazione è andata a buon fine
                        //this.toaster.pop("success", this.$translate.instant('generic.OPERATION_PERFORMED'), this.$translate.instant('courseDetail.QUESTION_SENT'));
                        // e l'aggiungo nella lista delle Comunicazioni
                        let chatUser = self.chatUsers.filter((chatUser: any) => chatUser.user.id === self.personToContact.id)[0];
                        if (chatUser) {
                            chatUser.notes.push(senecaResponse.response);
                            onMessage(senecaResponse.response, chatUser);
                            $inputMessage.val('');
                            scrollChatDiv();
                        }

                    }

                },
                error: function (data) {

                }
            });
        }
    }


    //Utils 
    //scroll chat section
    function scrollChatDiv() {
        setTimeout(function () {
            if ($chatSection_history[0]) {
                $chatSection_history[0].scrollTop = $chatSection_history[0].scrollHeight;
            }
        }, 100, false);
        //this.gotoChatDiv();
    }


    function refreshChatUsers() {
        $chatUsersSection_List.html('');
        $webinarUsersSection_list.html('');
        if (self.chatUsers == null) {
            self.chatUsers = [];
        }
        let intervalId = setInterval(function () {
            updateUsersChat();
            updateUsersWebinar();
        }, 5000);
        self.intervals.push(intervalId);
    }

    function updateUsersChat() {
        let userShow = false;
        for (let i = 0; i < self.chatUsers.length; i++) {
            let chatUser = self.chatUsers[i];
            let divId = "user-list-item-" + chatUser.user.id;
            let div = $("#" + divId);
            if (!div.length) {
                let chatUSerName = chatUser.user.forename + " " + chatUser.user.surname;
                let msgNotifyId = userMsgNotifyPrefix + chatUser.user.id;
                var divToAppend = '<div id="' + divId + '" class="user-list-item cursorPointer" data-userid="' + chatUser.user.id + '"><p><h5>' +
                    chatUSerName + '</h5></p><p><span id="' + msgNotifyId + '">Nessun nuovo messaggio</span></p></div>';

                // let divToAppend = '<div id="' + divId + '"data-userid="' + chatUser.user.id + '" class="item layout-align-center-center layout-row" layout="row" layout-align="center center"> '+ 
                //                   '<div class="itemName">' + chatUSerName + '</div><p style="color:white"><span id="' + msgNotifyId + '">Nessun nuovo messaggio</span></p></div>';
                $chatUsersSection_List.append(divToAppend);
                div = $("#" + divId);
                div.on("click", function () {
                    var userid = $(this).data("userid");
                    onBtnChat(null, userid);
                });
                userShow = true;
            }
            else {
                if (chatUser.timestamp > new Date()) {
                    div.show();
                    div.unbind("click");
                    div.on("click", function () {
                        var userid = $(this).data("userid");
                        onBtnChat(null, userid);
                    });
                    userShow = true;
                }
                else {
                    self.newMessages = self.newMessages - chatUser.newMessages;
                    if (self.newMessages < 0) self.newMessages = 0;
                    div.hide();
                }
            }
        }

        if (userShow) {
            $("chat-user-nothing").hide();
        }
        else {
            $("chat-user-nothing").show();
        }
    }


    function updateUsersWebinar() {
        if (!$webinarUsersSection || $webinarUsersSection.length < 1) $webinarUsersSection = $("#webinar-users-section");
        if (!$webinarUsersSection_list || $webinarUsersSection_list.length < 1) $webinarUsersSection_list = $webinarUsersSection.find('.userListDetails').find('.sectionDetails');
        let userShow = false;
        let count = 0;
        for (let i = 0; i < self.chatUsers.length; i++) {
            let chatUser = self.chatUsers[i];
            let divId = "user-webinar-list-item-" + chatUser.user.id;
            let div = $("#" + divId);
            if (!div.length) {
                let listUsername = chatUser.user.forename + " " + chatUser.user.surname;
                let divToAppend = '<div id="' + divId + '" class="item layout-align-center-center layout-row" layout="row" layout-align="center center"> ' +
                    '<button class="btnVoice md-button md-ink-ripple" type="button"><div layout="row" layout-align="center center" class="ng-scope layout-align-center-center layout-row"><i class="material-icons">&#xe02b;</i></div></button>' +
                    '<div class="itemName">' + listUsername + '</div></div>';
                $webinarUsersSection_list.append(divToAppend);
                div = $("#" + divId);
                userShow = true;
                count++;
            }
            else {
                if (chatUser.timestamp > new Date()) {
                    count++;
                    div.show();
                    userShow = true;
                }
                else {
                    div.hide();
                }
            }
        }
        if (userShow) {
            $("#webinar-user-nothing").hide();
        }
        else {
            $("#webinar-user-nothing").show();
        }

        if (count > 0) {
            $("#webinar-users-live-number").html("Partecipanti(" + count + ")")
            $("#webinar-users-live-number").show();
        }
        else {
            $("#webinar-users-live-number").hide();
        }
    }

    function sendImLive() {
        $chatUsersSection_List.html('');
        if (!self.chatUsers) {
            self.chatUsers = [];
        }

        if (!self.intervals) {
            self.intervals = [];
        }

        if (!self.teacherMode) {
            var intervalId = setInterval(function () {
                self.imLive();
            }, 60000);
            self.intervals.push(intervalId);
        }
    }

}());